import { AuthModalsProps } from '../AuthModals'
import { Button } from '@sc/components/Button'
import { Form } from '@sc/components/form/Form'
import { FormGroup } from '@sc/components/form/FormGroup'
import { Input } from '@sc/components/Input'
import { Label } from '@sc/components/Label'
import { Modal } from '@sc/patterns/Modal'
import { useForm } from 'react-hook-form'
import { useTrackOnce } from 'services/Analytics'
import { useTranslationPrefix } from 'hooks/useTranslationPrefix'

type PasswordResetForm = {
  email: string
}

export const ForgotPasswordModal = ({
  currentRoute,
  setRoute,
  onForgotPassword
}: Pick<AuthModalsProps, 'currentRoute' | 'setRoute' | 'onForgotPassword'>) => {
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
    setError
  } = useForm<PasswordResetForm>()

  const { t } = useTranslationPrefix('shared.forgotPasswordModal')

  useTrackOnce('forgot_password', {}, currentRoute === 'signin')

  return (
    <Modal.Root
      open={currentRoute === 'forgot'}
      onOpenChange={open => setRoute(prev => (open ? prev : null))}
    >
      <Modal.Content>
        <Modal.Title>{t('forgotPassword')}</Modal.Title>

        <Modal.Section>
          <Form
            id="forgot"
            onSubmit={handleSubmit(({ email }) =>
              onForgotPassword(email).finally(() => setRoute(null))
            )}
          >
            <FormGroup>
              <Label.Root>
                <Label.Primary htmlFor="reset-password-email">
                  {t('email')}
                </Label.Primary>
              </Label.Root>

              <Input
                type="email"
                error={errors.email}
                placeholder={t('placeholders.email')}
                autoComplete="email"
                {...register('email', {
                  required: { value: true, message: t('errors.email') }
                })}
              />
            </FormGroup>
          </Form>
        </Modal.Section>

        <Modal.Actions>
          <Modal.ActionsLeft>
            <Button onClick={() => setRoute('signin')} variant="secondary">
              {t('cancel')}
            </Button>
          </Modal.ActionsLeft>

          <Modal.ActionsRight>
            <Button
              variant="primary"
              type="submit"
              form="forgot"
              disabled={isSubmitting}
            >
              {isSubmitting ? t('sendingResetLink') : t('sendResetLink')}
            </Button>
          </Modal.ActionsRight>
        </Modal.Actions>
      </Modal.Content>
    </Modal.Root>
  )
}
