import { notify, t, dispatch } from './shared'
import { spacesActions } from 'store/spaces'
import { captureException } from 'sentry'
import * as actions from 'services/auth'
import * as membershipActions from 'services/memberships'
import * as spaceActions from 'services/spaces'

export const signUpAndSetupWorkspace = async (
  email: string,
  password: string,
  name: string
) => {
  try {
    const { session } = await actions.signUp(email, password, name)

    const { space, spaceMembership } = await spaceActions.creator(name)

    dispatch(spacesActions.userSignedUp(spaceMembership))

    notify({
      message: t('shared.signUpModal.successNotificationAccountCreated'),
      timeout: 5000
    })

    return { session, redirectTo: space.id }
  } catch (error) {
    if (error.status !== 400) {
      // Status 400 errors are due to duplicated user error.
      // We can safely ignore this errors and not report to sentry.
      captureException(error, { extra: { email } })
    }

    throw error
  }
}

export const signIn = async (email: string, password: string) => {
  try {
    const { session } = await actions.signIn(email, password)
    // Get the last visited workspace
    const workspaces = await membershipActions.authWorkspaces()

    const lastVisitedWorkspace = spaceActions.getLastVisited()

    const redirectTo =
      lastVisitedWorkspace && workspaces.includes(lastVisitedWorkspace)
        ? lastVisitedWorkspace
        : workspaces[0]

    notify({
      message: t('shared.signInModal.successNotificationWelcome'),
      timeout: 5000
    })

    return { session, redirectTo }
  } catch (error) {
    if (error.status !== 400) {
      // Status 400 errors are invalid credential errors (invalid password, user does not exists)
      // We can safely ignore this errors and not report to sentry.
      captureException(error, { extra: { email } })
    }

    throw error
  }
}

export const sendForgotPasswordEmail = async (email: string) => {
  try {
    await actions.anonForgotPassword(email)
  } catch (error) {
    // Only handle errors where status !== 404, that's when the user inputs an invalid email
    if (error.status !== 404) {
      captureException(error, { extra: { email } })
      throw error
    }
  }

  notify({
    timeout: 5000,
    message: t('alerts.forgotPassword')
  })
}
