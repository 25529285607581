import { AuthModalsProps } from '../AuthModals'
import { Body } from '@sc/components/Typography'
import { Button } from '@sc/components/Button'
import { Form } from '@sc/components/form/Form'
import { FormGroup } from '@sc/components/form/FormGroup'
import { Input } from '@sc/components/Input'
import { Label } from '@sc/components/Label'
import { Modal } from '@sc/patterns/Modal'
import { useForm } from 'react-hook-form'
import { useTranslationPrefix } from 'hooks/useTranslationPrefix'
import { trackEvent, useTrackOnce } from 'services/Analytics'

type SignInForm = {
  email: string
  password: string
}

export const SignInModal = ({
  currentRoute,
  setRoute,
  onSignIn
}: Pick<AuthModalsProps, 'currentRoute' | 'setRoute' | 'onSignIn'>) => {
  const {
    register,
    formState: { errors, isSubmitting },
    handleSubmit,
    setError
  } = useForm<SignInForm>()

  const { t } = useTranslationPrefix('shared.signInModal')

  useTrackOnce('sign_in_start', {}, currentRoute === 'signin')

  const onSubmit = async ({ email, password }) => {
    try {
      await onSignIn(email, password)
    } catch (error) {
      const message = error.message || "Something wen't wrong"
      setError('email', { message })
      trackEvent('SignInError', { message })
    }
  }

  return (
    <Modal.Root
      open={currentRoute === 'signin'}
      onOpenChange={open => setRoute(prev => (open ? prev : null))}
    >
      <Modal.Content>
        <Modal.Title>{t('logIn')}</Modal.Title>

        <Modal.Section>
          <Form id="login" onSubmit={handleSubmit(onSubmit)}>
            <FormGroup>
              <Label.Root>
                <Label.Primary htmlFor="email">{t('email')}</Label.Primary>
              </Label.Root>

              <Input
                type="email"
                error={errors.email}
                placeholder={t('placeholders.email')}
                autoComplete="email"
                {...register('email', {
                  required: {
                    value: true,
                    message: t('errors.email')
                  }
                })}
              />
            </FormGroup>

            <FormGroup>
              <Label.Root>
                <Label.Primary htmlFor="email">{t('password')}</Label.Primary>
              </Label.Root>

              <Input
                type="password"
                error={errors.email}
                placeholder={t(`placeholders.password`)}
                autoComplete="current-password"
                {...register('password', {
                  required: {
                    value: true,
                    message: t('errors.password')
                  }
                })}
              />
            </FormGroup>

            <Body variant="small">
              Don&apos;t yet have an account?{' '}
              <span
                style={{ textDecoration: 'underline', cursor: 'pointer' }}
                onClick={() => setRoute('signup')}
              >
                {t('signUp')}
              </span>
            </Body>
          </Form>
        </Modal.Section>

        <Modal.Actions>
          <Modal.ActionsLeft>
            <Modal.Close variant="secondary">{t('cancel')}</Modal.Close>
          </Modal.ActionsLeft>

          <Modal.ActionsRight>
            <Button variant="secondary" onClick={() => setRoute('forgot')}>
              {t('hasForgot')}
            </Button>

            <Button
              variant="primary"
              type="submit"
              form="login"
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Wait...' : t('signIn')}
            </Button>
          </Modal.ActionsRight>
        </Modal.Actions>
      </Modal.Content>
    </Modal.Root>
  )
}
