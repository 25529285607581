import React from 'react'
import { ForgotPasswordModal } from './ForgotPasswordModal'
import { SignInModal } from './SignInModal'
import { SignUpModal } from './SignUpModal'

export type AuthModalsRoutes = 'signin' | 'signup' | 'forgot'

export type AuthModalsProps = {
  currentRoute: AuthModalsRoutes
  setRoute: React.Dispatch<React.SetStateAction<AuthModalsRoutes>>
  onSignUp: (email: string, password: string, name: string) => Promise<void>
  onSignIn: (email: string, password: string) => Promise<void>
  onForgotPassword: (email: string) => Promise<void>
}

export const AuthModals = ({
  currentRoute,
  setRoute,
  onSignIn,
  onSignUp,
  onForgotPassword
}: AuthModalsProps) => {
  return currentRoute === 'signup' ? (
    <SignUpModal
      currentRoute={currentRoute}
      setRoute={setRoute}
      onSignUp={onSignUp}
    />
  ) : currentRoute === 'signin' ? (
    <SignInModal
      currentRoute={currentRoute}
      setRoute={setRoute}
      onSignIn={onSignIn}
    />
  ) : currentRoute === 'forgot' ? (
    <ForgotPasswordModal
      currentRoute={currentRoute}
      setRoute={setRoute}
      onForgotPassword={onForgotPassword}
    />
  ) : null
}
