import { ComponentProps, ReactNode } from 'react'
import * as CheckboxPrimitive from '@radix-ui/react-checkbox'
import { styled } from '@sc/theme/stitches.config'
import { Label } from './Label'

const CheckboxGroup = styled('div', {
  stackH: '$paddingBetweenLarge',
  alignItems: 'center'
})

const CheckboxRoot = styled(CheckboxPrimitive.Root, {
  all: 'unset',
  flexShrink: 0,
  display: 'grid',
  placeItems: 'center',
  borderWidth: '$control',
  borderStyle: 'solid',
  borderColor: '$strokePrimary',
  borderRadius: '$S',
  height: '$iconBase',
  width: '$iconBase',
  cursor: 'pointer',
  '&:disabled': {
    borderColor: '$strokeSecondary',
    cursor: 'default'
  }
})

const Rectangle = styled('div', {
  display: 'block',
  backgroundColor: '$fgMed',
  borderRadius: 'calc($radii$S - 0.2rem)',
  size: 'calc($sizes$iconBase - 0.4rem)'
})

export type CheckboxProps = {
  children: ReactNode
  id: string
  variant?: 'base' | 'small'
} & Omit<ComponentProps<typeof CheckboxRoot>, 'children' | 'id'>

export const Checkbox = ({ children, variant, ...props }: CheckboxProps) => {
  return (
    <CheckboxGroup>
      <CheckboxRoot {...props}>
        <CheckboxPrimitive.Indicator asChild>
          <Rectangle />
        </CheckboxPrimitive.Indicator>
      </CheckboxRoot>

      <Label.Root disabled={props.disabled} variant={variant}>
        <Label.Primary htmlFor={props.id}>{children}</Label.Primary>
      </Label.Root>
    </CheckboxGroup>
  )
}
