import NextLink from 'next/link'
import { AuthModalsProps } from '../AuthModals'
import { Button } from '@sc/components/Button'
import { Checkbox } from '@sc/components/Checkbox'
import { Form } from '@sc/components/form/Form'
import { FormGroup } from '@sc/components/form/FormGroup'
import { Input } from '@sc/components/Input'
import { Label } from '@sc/components/Label'
import { Modal } from '@sc/patterns/Modal'
import { useForm } from 'react-hook-form'
import { useTranslationPrefix } from 'hooks/useTranslationPrefix'
import { trackEvent, trackFbqEvent, useTrackOnce } from 'services/Analytics'

type SignUpForm = {
  name: string
  email: string
  password: string
  terms: boolean
}

export const SignUpModal = ({
  currentRoute,
  setRoute,
  onSignUp
}: Pick<AuthModalsProps, 'currentRoute' | 'setRoute' | 'onSignUp'>) => {
  const { t } = useTranslationPrefix('shared.signUpModal')

  useTrackOnce('sign_up_start', {}, currentRoute === 'signup')

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
    setError
  } = useForm<SignUpForm>()

  const onSubmit = async ({ email, name, password }) => {
    try {
      await onSignUp(email, password, name)
      trackEvent('sign_up')
      trackEvent('conversion', {
        send_to: 'AW-11170705069/rOkUCOLjkJ0YEK3dzc4p'
      })
      trackFbqEvent('CompleteRegistration')
    } catch (error) {
      const message = error.message || "Something wen't wrong"
      setError('email', { message })
      trackEvent('SignInError', { message })
    }
  }

  return (
    <Modal.Root
      open={currentRoute === 'signup'}
      onOpenChange={open => setRoute(prev => (open ? prev : null))}
    >
      <Modal.Content>
        <Modal.Title>{t('signUpTitle')}</Modal.Title>

        <Modal.Section>
          <Form id="signup" onSubmit={handleSubmit(onSubmit)}>
            <FormGroup>
              <Label.Root>
                <Label.Primary htmlFor="name">{t('name')}</Label.Primary>
              </Label.Root>

              <Input
                type="text"
                error={errors.name}
                placeholder={t('placeholders.name')}
                autoComplete="name"
                {...register('name', {
                  required: {
                    value: true,
                    message: t('errors.name')
                  }
                })}
              />
            </FormGroup>

            <FormGroup>
              <Label.Root>
                <Label.Primary htmlFor="email">{t('email')}</Label.Primary>
              </Label.Root>

              <Input
                type="email"
                error={errors.email}
                placeholder={t('placeholders.email')}
                autoComplete="email"
                {...register('email', {
                  required: {
                    value: true,
                    message: t('errors.email')
                  }
                })}
              />
            </FormGroup>

            <FormGroup>
              <Label.Root>
                <Label.Primary htmlFor="password">
                  {t('password')}
                </Label.Primary>
              </Label.Root>

              <Input
                type="password"
                placeholder={t('placeholders.password')}
                error={errors.password}
                autoComplete="current-password"
                {...register('password', {
                  required: {
                    value: true,
                    message: t('errors.password')
                  }
                })}
              />
            </FormGroup>

            <Checkbox id="terms" variant="small" required>
              {t('agreement.text1')}{' '}
              <NextLink href="/terms" style={{ textDecoration: 'underline' }}>
                {t('agreement.text2')}
              </NextLink>{' '}
            </Checkbox>
          </Form>
        </Modal.Section>

        <Modal.Actions>
          <Modal.ActionsLeft>
            <Modal.Close variant="secondary">{t('cancel')}</Modal.Close>
          </Modal.ActionsLeft>

          <Modal.ActionsRight>
            <Button
              variant="primary"
              type="submit"
              form="signup"
              disabled={isSubmitting}
            >
              {isSubmitting ? t('creating') : t('create')}
            </Button>
          </Modal.ActionsRight>
        </Modal.Actions>
      </Modal.Content>
    </Modal.Root>
  )
}
